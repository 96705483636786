import { ReactNode, createContext, useEffect, useState } from 'react'

import { BannerData, getEnabledBanner } from '../components/Banner'
import { getBookmark } from './Bookmark'
import { getUser, User } from './User'
import { switchBodyScroll } from './Util'

export type Bookmark = { [id: string]: true }
export type ToggleState = 'close' | 'menu' | 'search'

export type Store = {
  app: {
    loading: boolean
    isFirstLoad: boolean
  }
  entity: {
    user: User | undefined
    bookmark: Bookmark
    toasts: string[]
  }
  ui: {
    banner: BannerData | undefined
    showMenu: boolean
    showContactModal: boolean
  }
  action: {
    reload: () => void
    disableBanner: () => void
    setUser: (user: User | undefined) => void
    updateBookmark: (articleIds: string[]) => void
    setShowMenu: (show: boolean) => void
    setShowContactModal: (show: boolean) => void
    addToasts: (message: string) => void
    removeToasts: () => void
  }
}
const initialStore: Store = {
  app: {
    loading: true,
    isFirstLoad: true,
  },
  entity: {
    user: undefined,
    bookmark: {},
    toasts: [],
  },
  ui: {
    banner: undefined,
    showMenu: false,
    showContactModal: false,
  },
  action: {
    reload: () => console.log('reload'),
    disableBanner: () => console.log('disableBanner'),
    setUser: () => console.log('setUser'),
    updateBookmark: () => console.log('updateBookmark'),
    setShowMenu: () => console.log('setShowMenu'),
    setShowContactModal: () => console.log('setShowContactModal'),
    addToasts: () => console.log('addToasts'),
    removeToasts: () => console.log('removeToasts'),
  },
}

export const StoreContext = createContext(initialStore)

type StoreProviderProps = {
  children: ReactNode
}
export const StoreProvider = ({ children }: StoreProviderProps) => {
  const [loading, setLoading] = useState(true)
  const [isFirstLoad, setIsFirstLoad] = useState(true)

  const [user, setUser] = useState<User>()
  const [bookmark, setBookmark] = useState<Bookmark>({})
  const [toasts, setToasts] = useState<string[]>([])

  const [banner, setBanner] = useState<BannerData>()
  const [showMenu, setShowMenu] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)

  const reload = () => {
    setLoading(true)
    setTimeout(() => setShowMenu(false), 500)
  }
  const disableBanner = () => setBanner(undefined)
  const updateBookmark = (articleIds: string[]) => {
    const map: Bookmark = {}
    articleIds.forEach(id => (map[id] = true))
    setBookmark(map)
  }
  const addToasts = (message: string) =>
    setToasts(toasts => [...toasts, message])
  const removeToasts = () => setToasts(toasts.slice(1))

  useEffect(() => switchBodyScroll(!showMenu), [showMenu])

  useEffect(() => {
    if (!loading) {
      return
    }

    if (user === undefined) {
      // 取得に失敗するとリトライして時間がかかるので、レンダリング後に取得する
      ;(async () => {
        const tmpUser = await getUser()
        // TODO: 全て取得する
        const bookmarkArticleIds = (await getBookmark(tmpUser.isSignIn, 1, 200))
          .ids
        updateBookmark(bookmarkArticleIds)
        setUser(tmpUser)
      })()
    }

    // loadingのopacityのアニメーションをしっかり表示するため
    setTimeout(() => {
      // useTranslationは別のContextを使っている関係で、エラーが発生してしまう
      const path = window.location.pathname.replace(/^\/(ja|en)\/?/, '/')
      const locale = window.location.pathname.startsWith('/en') ? 'en' : 'ja'
      setBanner(getEnabledBanner(path, locale))
      setIsFirstLoad(false)
      setLoading(false)
    }, 500)
  }, [loading])

  return (
    <StoreContext.Provider
      value={{
        app: { loading, isFirstLoad },
        entity: { user, bookmark, toasts },
        ui: { banner, showMenu, showContactModal },
        action: {
          reload,
          disableBanner,
          setUser,
          updateBookmark,
          setShowMenu,
          setShowContactModal,
          addToasts,
          removeToasts,
        },
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}
