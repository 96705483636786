import { captureException, captureMessage } from '@sentry/gatsby'

import { isLocal } from './Util'

export const sendError = (error: unknown) => {
  if (isLocal) {
    console.error(error)
    return
  }

  captureException(error)
}

export const sendWarning = (message: string) => {
  if (isLocal) {
    console.warn(message)
    return
  }

  captureMessage(message, 'warning')
}
