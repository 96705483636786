import { init } from '@sentry/gatsby'

import { isLocal } from './src/models/Util'

init({
  // publicなURLかつ、環境によって変更もしないのでそのままコミット
  dsn: 'https://9ad53673564b1907daf67b50d0a392cb@o4504608571523072.ingest.sentry.io/4505820857499648',
  environment: isLocal ? 'development' : 'production',
  debug: isLocal,
  enabled: !isLocal,
})
