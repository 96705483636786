import { useInView } from 'react-intersection-observer'

export const useElementVisibility = (viewedComponent: string) => {
  return useInView({
    threshold: 0.5,
    triggerOnce: true,
    onChange(inView) {
      if (inView) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({
          event: 'viewComponent',
          viewedComponent,
        })
      }
    },
  })
}
