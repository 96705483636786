import { getCookie } from './Cookie'

const GOOGLE_ANALYTICS_CONTAINER_ID = 'ZMZWBD7PH8'

const readCookieWithRetries = async (
  cookieKey: string,
): Promise<string | undefined> => {
  const sleep = () => new Promise(resolve => setTimeout(resolve, 200))
  const maxRetryCount = 5
  let retryCount = 0
  let data: string | undefined

  // gtag setup may not have completed on read
  while (typeof data === 'undefined' && retryCount < maxRetryCount) {
    await sleep()
    // Referece these links:
    // https://support.google.com/analytics/answer/11397207?hl=en
    // https://www.algolia.com/doc/guides/sending-events/concepts/usertoken/#google-analytics
    data = getCookie(cookieKey)
    retryCount++
  }

  return data
}

export const getAnalyticsUserId = async (): Promise<string | undefined> => {
  return readCookieWithRetries('_ga').then(data => {
    return data?.split('.').slice(-2).join('.')
  })
}

export const getAnalyticsSessionId = async (): Promise<string | undefined> => {
  return readCookieWithRetries(`_ga_${GOOGLE_ANALYTICS_CONTAINER_ID}`).then(
    data => {
      return data?.split('.')[2]
    },
  )
}

type UserProperties =
  | {
      user_penultimate_digit: string
      user_last_digit: string
    }
  | {
      is_signin: boolean
    }
  | {
      sample_property: string
    }

export const setUser = (userProperties: UserProperties) => {
  if (typeof gtag === 'undefined') {
    return
  }

  gtag('set', 'user_properties', userProperties)
}

export const setUserId = (userId: string) => {
  if (typeof gtag === 'undefined') {
    return
  }

  gtag('config', `G-${GOOGLE_ANALYTICS_CONTAINER_ID}`, { user_id: userId })
}

type Event =
  | {
      name: 'feature_test'
      param: {
        feature_test_id: string
        feature_test_flag: boolean
      }
    }
  | {
      name: 'component_click'
      param: {
        clicked_component: string
        clicked_label?: string
        clicked_value?: string
      }
    }
  | {
      name: 'sample_event'
      param: {
        sample_param_1: string
      }
    }

export const sendEvent = (event: Event) => {
  if (typeof gtag === 'undefined') {
    return
  }

  gtag('event', event.name, event.param)
}
