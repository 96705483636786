const ExternalLink = () => (
  <svg width="22" height="22" viewBox="0 0 22 22" fill="none">
    <path
      d="M12.375 5.5H16.5V9.625"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.667 11.9168V15.5835H6.41699V7.3335H10.0837"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 10.9997L16.0417 5.95801"
      stroke="white"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ExternalLink
