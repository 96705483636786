import { navigate } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { useEffect, useState } from 'react'

export type Locale = 'ja' | 'en'

export const useTranslation = () => {
  const intl = useIntl()
  const locale = intl.locale as Locale
  const [path, setPath] = useState('/')
  const location = typeof window !== 'undefined' ? window.location : undefined

  useEffect(() => {
    if (location === undefined) {
      return
    }

    // パスから言語を削除する
    // /ja/xxx => /xxx
    // /en  => /
    // /aaa/bbb => /aaa/bbb
    setPath(location.pathname.replace(/^\/(ja|en)\/?/, '/'))
  }, [location])

  return {
    t: (id: string) => intl.formatMessage({ id }),
    locale,
    path,
    navigate: (path: string) => navigate(buildTargetLocalePath(locale, path)),
  }
}

// 指定された言語に合わせてパスを生成する
// ja, / => /
// en, / => /en
// en, /xxx => /en/xxx
export const buildTargetLocalePath = (targetLocale: Locale, path: string) =>
  `${targetLocale === 'en' ? '/en' : ''}${path}`.replace(/(.+)\/$/, '$1')

export type PageContext = {
  language: Locale
}
