export const setCookie = (key: string, value: string): void => {
  document.cookie = `${key}=${value}; expires=Fri, 31 Dec 9999 23:59:59 GMT; path=/; samesite=strict`
}

export const getCookie = (key: string): string | undefined => {
  return document.cookie
    .split('; ')
    .map(row => row.split('='))
    .map(([key, value]) => ({ key, value }))
    .find(row => row.key === key)?.value
}

export const removeCookie = (key: string): void => {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 GMT; path=/; samesite=strict`
}
