import { useContext } from 'react'
import { navigate } from 'gatsby-plugin-intl'

import { getLocalData, setLocalData } from './LocalData'
import { StoreContext } from './Store'
import { api, getCsrf } from './User'

const LOCAL_DATA_KEY = '20240828_bookmark'

export const useBookmark = () => {
  const { entity, action } = useContext(StoreContext)

  const switchBookmark = async (articleId: string) => {
    const isBookmarked = entity.bookmark[articleId] === true
    const bookmarkArticleIds = []
    for (const key in entity.bookmark) {
      if (entity.bookmark[key] === true) {
        bookmarkArticleIds.push(key)
      }
    }

    if (
      // 未ログインの場合は、3つまでしか保存できない
      !isBookmarked &&
      bookmarkArticleIds.length >= 3 &&
      entity.user?.isSignIn === false
    ) {
      navigate('/signup?limit=save')
      return
    }

    if (isBookmarked) {
      const index = bookmarkArticleIds.indexOf(articleId)
      bookmarkArticleIds.splice(index, 1)
    } else {
      bookmarkArticleIds.unshift(articleId)
    }

    action.updateBookmark(bookmarkArticleIds)

    if (entity.user?.isSignIn) {
      const token = await getCsrf()
      const headers = { 'X-CSRF-Token': token }

      if (isBookmarked) {
        await api.delete(`/articles/saved?articles=${articleId}`, { headers })
      } else {
        await api.post(
          '/articles/saved',
          { articles: [articleId] },
          { headers },
        )
      }
      return
    }

    setLocalData(LOCAL_DATA_KEY, bookmarkArticleIds.join(','))

    // TODO: 更新に失敗したらStoreを戻す
  }

  return { switchBookmark }
}

export const getBookmark = async (isSignIn: boolean, page = 1, limit = 30) => {
  if (isSignIn) {
    const queryParam = `?page=${page}&articles_per_page=${limit}`
    const response = await api.get<{ articles: string[]; total_count: number }>(
      `/articles/saved${queryParam}`,
    )
    return { ids: response.data.articles, total: response.data.total_count }
  }

  const localData = getLocalData(LOCAL_DATA_KEY)
  const ids =
    localData === undefined || localData === '' ? [] : localData.split(',')
  return { ids, total: ids.length }
}

export const moveBookmark = async () => {
  const localData = getLocalData(LOCAL_DATA_KEY)
  const localIds =
    localData === undefined || localData === '' ? [] : localData.split(',')

  if (localIds.length === 0) {
    return
  }

  const token = await getCsrf()
  const headers = { 'X-CSRF-Token': token }
  await api.post('/articles/saved', { articles: localIds }, { headers })

  setLocalData(LOCAL_DATA_KEY, '')
}
