import { useContext } from 'react'

import { sendEvent, setUser } from './Analytics'
import { StoreContext } from './Store'
import { getLocalData, removeLocalData, setLocalData } from './LocalData'

type FeatureTest = {
  id: string
  name: string
  description: string
  startDate: Date
  endDate: Date
  targetPenultimateDigit: string[]
  targetLastDigit: string[]
}

const featureTests: FeatureTest[] = [
  {
    id: '2023_force_target',
    name: '強制ターゲット確認用',
    description: '強制なターゲットなのかを確認するため',
    startDate: new Date('2023/11/21 12:00:00'),
    endDate: new Date('2100/12/31 23:59:59'),
    targetPenultimateDigit: [],
    targetLastDigit: [],
  },
]

export const getEnabledTestIds = (userId: string | undefined) => {
  const forceTarget = checkForceTarget()
  if (forceTarget === undefined && userId === undefined) {
    return []
  }

  const { penultimateDigit, lastDigit } = convertToDigits(userId)
  if (userId !== undefined) {
    setUser({
      user_penultimate_digit: penultimateDigit,
      user_last_digit: lastDigit,
    })
  }

  // Use browser time, which may be different depending on settings used by the user.
  // TODO: If we need to check more strictly, we will change the process to get the server's time.
  const nowDate = new Date()

  return featureTests
    .filter(featureTest => {
      if (forceTarget !== undefined) {
        return forceTarget
      }

      const enable = checkFeatureTest(
        featureTest,
        penultimateDigit,
        lastDigit,
        nowDate,
      )

      sendEvent({
        name: 'feature_test',
        param: {
          feature_test_id: featureTest.id,
          feature_test_flag: enable,
        },
      })

      return enable
    })
    .map(featureTest => featureTest.id)
}

export const useFeatureTest = (featureTestId: string) => {
  const { entity } = useContext(StoreContext)
  return (
    entity.user !== undefined &&
    entity.user.enabledTestIds.includes(featureTestId)
  )
}

/*
  Returns true when all of the following conditions are satisfied
  - The current time is within the duration of the test
  - The user ID is the target
*/
const checkFeatureTest = (
  featureTest: FeatureTest,
  penultimateDigit: string,
  lastDigit: string,
  nowDate: Date,
) => {
  if (nowDate < featureTest.startDate || nowDate > featureTest.endDate) {
    return false
  }

  return (
    featureTest.targetPenultimateDigit.includes(penultimateDigit) &&
    featureTest.targetLastDigit.includes(lastDigit)
  )
}

const checkForceTarget = () => {
  const key = 'ft'
  const queryParams = new URLSearchParams(window.location.search)
  const forceTarget = queryParams.get(key) || getLocalData(key)

  if (forceTarget === 'reset') {
    removeLocalData(key)
    return
  }

  if (forceTarget === 'true') {
    setLocalData(key, 'true')
    return true
  }
  if (forceTarget === 'false') {
    setLocalData(key, 'false')
    return false
  }

  return
}

const convertToDigits = (userId: string | undefined) => {
  if (userId === undefined) {
    return { penultimateDigit: '0', lastDigit: '0' }
  }

  const digits = userId.replace(/[^0-9]/g, '')
  const penultimateDigit = digits.slice(-2, -1)
  const lastDigit = digits.slice(-1)

  return { penultimateDigit, lastDigit }
}
