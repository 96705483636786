import { ReactNode } from 'react'
import { Link as GatsbyLink } from 'gatsby'

import {
  Locale,
  buildTargetLocalePath,
  useTranslation,
} from '@models/Translation'

type LinkProps = {
  children: ReactNode
  to: string
  locale?: Locale
  className?: string
  onClick?: () => void
  target?: string
}
const Link = ({
  children,
  to,
  locale,
  className,
  onClick,
  target,
  ...delegated
}: LinkProps) => {
  const { locale: currentLocale } = useTranslation()
  const commonProps = { className, onClick, ...delegated }

  const zozoDomainRegex = /https:\/\/[^\/]*?(zozo|zozonext)\.com/
  if (to.match(zozoDomainRegex)) {
    return (
      // 自社サイトかつreferrerを取得したいので、noopenerのみ利用
      // eslint-disable-next-line react/jsx-no-target-blank
      <a href={to} {...commonProps} target="_blank" rel="noopener">
        {children}
      </a>
    )
  }

  if (to.startsWith('http')) {
    return (
      <a href={to} {...commonProps} target="_blank" rel="noreferrer">
        {children}
      </a>
    )
  }

  if (target === '_blank') {
    return (
      <a
        href={buildTargetLocalePath(currentLocale, to)}
        {...commonProps}
        target="_blank"
        rel="noreferrer"
      >
        {children}
      </a>
    )
  }

  if (locale === undefined) {
    return (
      <GatsbyLink
        to={buildTargetLocalePath(currentLocale, to)}
        {...commonProps}
      >
        {children}
      </GatsbyLink>
    )
  }

  return (
    <GatsbyLink to={buildTargetLocalePath(locale, to)} {...commonProps}>
      {children}
    </GatsbyLink>
  )
}

export default Link
