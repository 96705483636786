import { getCookie, removeCookie, setCookie } from './Cookie'

export const setLocalData = (key: string, value: string): void => {
  isLocalStorageAvailable()
    ? localStorage.setItem(key, value)
    : setCookie(key, value)
}

export const getLocalData = (key: string): string | undefined => {
  const localStorageResult =
    isLocalStorageAvailable() && localStorage.getItem(key)
  if (typeof localStorageResult === 'string') {
    return localStorageResult
  }

  return getCookie(key)
}

export const removeLocalData = (key: string): void => {
  if (isLocalStorageAvailable()) {
    localStorage.removeItem(key)
  }

  removeCookie(key)
}

// https://developer.mozilla.org/ja/docs/Web/API/Web_Storage_API/Using_the_Web_Storage_API#localstorage_%E3%81%AE%E6%A9%9F%E8%83%BD%E6%A4%9C%E5%87%BA
const isLocalStorageAvailable = () => {
  let storage
  try {
    storage = window['localStorage']
    const x = '__storage_test__'
    storage.setItem(x, x)
    storage.removeItem(x)
    return true
  } catch (e) {
    return false
  }
}
