import {
  AuthenticationDetails,
  CognitoUser,
  CognitoUserPool,
} from 'amazon-cognito-identity-js'
import axios from 'axios'
import {
  getAnalyticsUserId,
  getAnalyticsSessionId,
  setUserId,
  setUser,
} from './Analytics'
import { sendWarning } from './Sentry'
import { getEnabledTestIds } from './FeatureTest'
import { Locale } from './Translation'

export type User =
  | {
      isSignIn: false
      userId: string | undefined
      sessionId: string | undefined
      enabledTestIds: string[]
    }
  | {
      isSignIn: true
      userId: string | undefined
      sessionId: string | undefined
      enabledTestIds: string[]
      email: string
      subscription: boolean
      locale: Locale
      isExternal: boolean
    }

const API_URL = process.env.GATSBY_BACKEND_URL ?? ''
export const api = axios.create({
  baseURL: API_URL,
  withCredentials: true,
})
const userPool = new CognitoUserPool({
  UserPoolId: process.env.GATSBY_COGNITO_USER_POOL_ID ?? '',
  ClientId: process.env.GATSBY_COGNITO_CLIENT_ID ?? '',
})

export const signUp = async (email: string, passowrd: string): Promise<void> =>
  new Promise((resolve, reject) =>
    userPool.signUp(email, passowrd, [], [], error => {
      if (error) {
        reject(error)
      }

      resolve()
    }),
  )

export const forgetPassword = async (email: string): Promise<void> => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  })

  return new Promise((resolve, reject) =>
    cognitoUser.forgotPassword({
      onSuccess: resolve,
      onFailure: reject,
    }),
  )
}

export const resetPassword = async (
  email: string,
  code: string,
  passowrd: string,
): Promise<string> => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  })

  return new Promise((resolve, reject) =>
    cognitoUser.confirmPassword(code, passowrd, {
      onSuccess: resolve,
      onFailure: reject,
    }),
  )
}

export const confirmMail = async (
  email: string,
  code: string,
): Promise<void> => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  })

  return new Promise((resolve, reject) =>
    cognitoUser.confirmRegistration(code, false, error => {
      if (error) {
        reject(error)
      }

      resolve()
    }),
  )
}

export const resendConfirmMail = async (email: string): Promise<void> => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  })

  return new Promise((resolve, reject) =>
    cognitoUser.resendConfirmationCode(error => {
      if (error) {
        reject(error)
      }

      resolve()
    }),
  )
}

export const changeCognitoMail = async (
  currentEmail: string,
  password: string,
  newEmail: string,
): Promise<void> => {
  const cognitoUser = new CognitoUser({
    Username: currentEmail,
    Pool: userPool,
  })

  const authenticationDetails = new AuthenticationDetails({
    Username: currentEmail,
    Password: password,
  })

  const attributes = [
    {
      Name: 'email',
      Value: newEmail,
    },
  ]

  return new Promise((resolve, reject) =>
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        cognitoUser.updateAttributes(attributes, error => {
          if (error) {
            reject(error)
          }

          resolve()
        })
      },
      onFailure: error => {
        reject(error)
      },
    }),
  )
}

export const changeBackendMail = async (
  email: string,
  passowrd: string,
): Promise<void> => {
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: passowrd,
  })
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  })

  return new Promise((resolve, rejects) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: async result => {
        const token = await getCsrf()
        const headers = {
          'X-CSRF-Token': token,
        }
        const idToken = result.getIdToken().getJwtToken()
        const refreshToken = result.getRefreshToken().getToken()

        api
          .put(
            '/profile/email',
            { jwt: idToken, rt: refreshToken },
            { headers },
          )
          .catch(rejects)
          .then(() => resolve())
      },
      onFailure: rejects,
    })
  })
}

export const confirmNewMail = async (
  email: string,
  password: string,
  code: string,
): Promise<string> => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  })
  const authenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  })

  return new Promise((resolve, reject) =>
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        cognitoUser.verifyAttribute('email', code, {
          onSuccess: resolve,
          onFailure: reject,
        })
      },
      onFailure: reject,
    }),
  )
}

export const changePassword = async (
  email: string,
  password: string,
  newPassword: string,
): Promise<void> => {
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  })
  const authenticationDetails = new AuthenticationDetails({
    Username: email,
    Password: password,
  })

  return new Promise((resolve, reject) =>
    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: () => {
        cognitoUser.changePassword(password, newPassword, error => {
          if (error) {
            reject(error)
          }

          resolve()
        })
      },
      onFailure: reject,
    }),
  )
}

export const signIn = async (
  email: string,
  passowrd: string,
): Promise<void> => {
  const authDetails = new AuthenticationDetails({
    Username: email,
    Password: passowrd,
  })
  const cognitoUser = new CognitoUser({
    Username: email,
    Pool: userPool,
  })

  return new Promise((resolve, rejects) => {
    cognitoUser.authenticateUser(authDetails, {
      onSuccess: async result => {
        const token = await getCsrf()
        const idToken = result.getIdToken().getJwtToken()
        const refreshToken = result.getRefreshToken().getToken()
        const headers = {
          'X-CSRF-Token': token,
          'X-Refresh-Token': refreshToken,
          Authorization: `Bearer ${idToken}`,
        }

        api
          .post('/signin', {}, { headers })
          .catch(rejects)
          .then(() => resolve())
      },
      onFailure: rejects,
    })
  })
}

export const buildGoogleLink = (locale: Locale) => {
  const domain = process.env.GATSBY_DOMAIN ?? ''
  const cognitoUrl = process.env.GATSBY_COGNITO_URL ?? ''
  const redirectUrl = `${domain}${locale === 'en' ? '/en' : ''}/redirect-google`
  const clientId = process.env.GATSBY_COGNITO_CLIENT_ID ?? ''
  return `${cognitoUrl}/oauth2/authorize?identity_provider=Google&redirect_uri=${redirectUrl}&response_type=code&client_id=${clientId}&scope=email+openid`
}

export const signInWithGoogle = async (code: string, locale: Locale) => {
  const domain = process.env.GATSBY_DOMAIN ?? ''
  const cognitoUrl = process.env.GATSBY_COGNITO_URL ?? ''
  const params = {
    grant_type: 'authorization_code',
    client_id: process.env.GATSBY_COGNITO_CLIENT_ID ?? '',
    code,
    redirect_uri: `${domain}${locale === 'en' ? '/en' : ''}/redirect-google`,
  }
  const headersForGoogle = {
    'Content-Type': 'application/x-www-form-urlencoded',
  }
  const result = await api.post<{ id_token: string; refresh_token: string }>(
    `${cognitoUrl}/oauth2/token`,
    params,
    { headers: headersForGoogle },
  )

  const token = await getCsrf()
  const idToken = result.data.id_token
  const refreshToken = result.data.refresh_token
  const headers = {
    'X-CSRF-Token': token,
    'X-Refresh-Token': refreshToken,
    Authorization: `Bearer ${idToken}`,
  }

  await api.post('/signin', {}, { headers })
}

export const signOut = async () => {
  const token = await getCsrf()
  const headers = { 'X-CSRF-Token': token }
  await api.post('/signout', {}, { headers })
}

type Profile = {
  userId: string
  email: string
  locale: Locale
  isExternal: boolean
}
type RawProfile = {
  sub: string
  email: string
  language: 'jp' | 'en'
  is_external: boolean
}
export const getProfile = async (): Promise<Profile> => {
  const result = await api.get<RawProfile>('/profile')
  return {
    userId: result.data.sub,
    email: result.data.email,
    locale: result.data.language === 'en' ? 'en' : 'ja',
    isExternal: result.data.is_external,
  }
}

export const updateLanguage = async (locale: Locale) => {
  const language = locale === 'en' ? 'en' : 'jp'
  const token = await getCsrf()
  const headers = { 'X-CSRF-Token': token }
  await api.put('/profile', { language }, { headers })
}

export const getSubscription = async (): Promise<boolean> => {
  const result = await api.get<{ enable: boolean }>('/subscriptions')
  return result.data.enable
}

export const updateSubscription = async (subscription: boolean) => {
  const token = await getCsrf()
  const headers = { 'X-CSRF-Token': token }
  await api.post('/subscriptions', { enable: subscription }, { headers })
}

export const getUser = async (): Promise<User> => {
  const sessionId = await getAnalyticsSessionId()

  try {
    const profile = await getProfile()
    const subscription = await getSubscription()
    setUserId(profile.userId)
    setUser({ is_signin: true })

    return {
      isSignIn: true,
      sessionId,
      enabledTestIds: getEnabledTestIds(profile.userId),
      subscription,
      ...profile,
    }
  } catch (e) {
    // 失敗したら未ログインということで握りつぶす
  }

  // TODO: UserIdなどの取得に時間がかかりすぎなので、対応策を考える
  const userId = await getAnalyticsUserId()
  if (userId === undefined || sessionId === undefined) {
    console.log(userId, sessionId)
    sendWarning("GA userId, sessionId not set, can't put events")
  }

  return {
    isSignIn: false,
    userId,
    sessionId,
    enabledTestIds: getEnabledTestIds(userId),
  }
}

export const getCsrf = async (): Promise<string> => {
  const result = await api.get<{ csrf_token: string }>('/csrf')
  return result.data.csrf_token
}

export const deleteAccount = async (): Promise<void> => {
  const token = await getCsrf()
  const headers = { 'X-CSRF-Token': token }
  await api.delete('/account', { headers })
}

export const sendContact = async (
  name: string,
  email: string,
  category: string,
  detail: string,
) => {
  const token = await getCsrf()
  const headers = { 'X-CSRF-Token': token }
  await api.post('/contact-us', { name, email, category, detail }, { headers })
}
