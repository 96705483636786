const CrossMark = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <rect
      width="1.14839"
      height="21.2452"
      rx="0.574194"
      transform="matrix(-0.710061 0.70414 -0.710061 -0.70414 16 14.9598)"
      fill="white"
    />
    <rect
      width="1.14839"
      height="21.2452"
      rx="0.574194"
      transform="matrix(0.710061 0.70414 -0.710061 0.70414 15.0854 0.231445)"
      fill="white"
    />
  </svg>
)

export default CrossMark
